.skill-header {
  display: flex;
  height: 100%;
  width: 1200px;
  margin: 0 auto;
  align-items: center;

  .header-logo {
    height: 50px;
    display: block;
  }

  .header-navbar {
    flex: 1;
    display: flex;
    list-style: none;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    color: #333;
    line-height: 22px;

    li:not(:last-child) {
      margin-right: 40px;
    }

    li {
      a {
        color: #969799;
      }
    }

    .header-navbar-active {
      position: relative;
      color: #2574ff;
      padding-bottom: 15px;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        right: 0;
        bottom: -8px;
        height: 3px;
        width: 20px;
        background-color: #2574ff;
        border-radius: 20px;
        transform: translateX(-50%);
      }
    }
  }

  .header-search {
    background: #f1f1f1;
    vertical-align: top;
    border-radius: 40px;
    border: none;
    width: 240px;
    height: 40px;
    box-shadow: none;

    .ant-input {
      background: #f1f1f1;

      &::placeholder {
        color: #969799;
      }
    }
  }

  .header-avatar {
    margin-right: 10px;
  }
}

.menu-active {
  color: #2574ff !important;
}

.new-forgot {
  text-align: end;
  color: #9199a1;
  margin-bottom: 15px;
  cursor: pointer;
}

.new-register {
  text-align: center;
  margin: 10px 0;
  font-size: 18px;
  color: #2574ff;
  cursor: pointer;
}

.qrcode_login {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.code-input {
  height: 100%;

  .ant-input-wrapper {
    height: 100%;

    .ant-input-group-addon {
      padding: 0;

      .code-btn {
        width: 110px;
        height: 100%;
        border-radius: 0 7px 7px 0;
      }
    }
  }
}

.qrcode-wrapper {
  margin-bottom: 40px;
  text-align: center;

  .qrcode {
    width: 160px;
    height: 160px;
    background: #ccc;
    display: inline-block;
    margin-bottom: 20px;
  }
}

@primary-color: #2574ff;@border-radius-base: 8px;