.skill-footer {
  display: flex;
  width: 1200px;
  height: 170px;
  margin: 0 auto;
  padding: 37px 0;
  align-items: center;
  justify-content: center;

  .footer-qrcode {
    .qrcode {
      width: 130px;
    }
  }

  .footer-desc {
    display: flex;
    flex-direction: column;

    ul {
      display: flex;
      list-style: none;
    }

    .footer-desc-content,
    .footer-desc-copyright {
      font-size: 12px;
      font-weight: 400;
      color: #c8c9cc;
      line-height: 17px;
    }

    .footer-desc-content {
      margin-top: 30px;

      li:not(:last-child) {
        flex: 1;
      }
    }

    .footer-desc-copyright {
      li {
        &:not(:last-child) {
          flex: 1;
        }

        a {
          color: hsla(0, 0%, 100%, 0.8);
        }
      }
    }

    .footer-desc-links {
      font-size: 16px;
      position: relative;
      margin: 0;

      li {
        margin: 0 16px;

        &:last-child {
          margin-right: 0;
        }

        &:first-child {
          margin-left: 0;
        }

        a {
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          line-height: 20px;
        }
      }
    }
  }
}

@primary-color: #2574ff;@border-radius-base: 8px;