#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

.ant-tabs-nav {
  &::before {
    border-bottom: none !important;
  }

  .ant-tabs-ink-bar {
    height: 3px;
    background: #2574ff;
    border-radius: 4px;
  }
}

@primary-color: #2574ff;@border-radius-base: 8px;