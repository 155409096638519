.header {
  position: fixed;
  width: 100%;
  height: 85px;
  padding: 0 20px;
  top: 0;
  left: 0;
  z-index: 9;
  margin: 0 auto;
  background: #fff;
}

.footer {
  background-color: #333;
  color: #fff;
  padding: 0;
}

@primary-color: #2574ff;@border-radius-base: 8px;